import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { useTranslation } from "react-i18next";
import { Camera, CameraMode, OutputImageFormat } from "@amzn/hvh-camera-ui";
import jsQR from "jsqr";
import beep from "/src/media/beep.mp3";
import "./ScanQR.scss";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { getQR } from "src/utils/qr-helper";
import { ConnectWidgetData } from "../types";
import { Card } from "@amzn/stencil-react-components/card";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";

interface ScanQRProps {
  onStartCall: (x: ConnectWidgetData | null) => void;
}

export const ScanQR = ({ onStartCall }: ScanQRProps) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();
  const [cameraReady, setCameraReady] = useState(false);
  const [qrCode, setQrCode] = useState<ConnectWidgetData | null>(null);
  const beepRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const onShot = useCallback(
    (imageData: ImageData) => {
      let code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });

      if (!code || !code.data) {
        return;
      }

      beepRef.current?.play();

      setQrCode(getQR(code.data));
    },
    [onStartCall]
  );

  const handleStartCall = () => onStartCall(qrCode);

  const onCameraReady = useCallback(() => {
    setCameraReady(true);
  }, []);
  const style: React.CSSProperties = {
    position: "absolute",
    width: "2rem",
    height: "2rem",
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(224, 25, 25, 0.7)",
  };

  return (
    <Col gridGap="S300" display="flex" width={"100%"}>
      <H1 id="page-title" ref={titleRef} tabIndex={-1} style={{ fontSize: 24, lineHeight: 1.5 }}>
        {t("RVP-ScanQR-Code-Title")}
      </H1>
      <audio ref={beepRef as any} src={beep}></audio>
      <div className="camera-wrapper">
        <Camera
          mode={CameraMode.Auto}
          onShot={onShot}
          autoFramesInterval={250}
          onReady={onCameraReady}
          facingMode="environment"
          outputFormat={OutputImageFormat.ImageData}
          startButtonText={t("RVP-ScanQR-Code-Scan")}
        />
        {cameraReady && (
          <div data-testid="camera-markers" className="camera-markers">
            <div className="marker lt" />
            <div className="marker rt" />
            <div className="marker lb" />
            <div className="marker rb" />
          </div>
        )}
      </div>
      {qrCode && (
        <MessageBanner type={MessageBannerType.Success} aria-live="polite">
          {t("RVP-ScanQR-Code-scan-success")}
        </MessageBanner>
      )}

      <Card>
        <Text>{t("RVP-ScanQR-Code-Description")}</Text>
      </Card>
      <Button
        onClick={handleStartCall}
        disabled={!qrCode}
        data-testid="test-join-button"
        variant={ButtonVariant.Primary}
      >
        {t("RVP-ScanQR-Code-StartCall-Button")}
      </Button>
    </Col>
  );
};
